import { openDB } from 'idb';
import axios from 'axios';
import ImageResizer from '../components/image/ImageResizer';

// IndexedDB setup
const DB_NAME = 'ImageCache';
const URL_STORE = 'signedUrls';
const IMAGE_STORE = 'images';
const DB_VERSION = 1;
const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    db.createObjectStore(URL_STORE);
    db.createObjectStore(IMAGE_STORE);
  },
});

// Utility functions for IndexedDB operations
async function getFromStore(storeName, key) {
  const db = await dbPromise;
  return db.get(storeName, key);
}

async function setInStore(storeName, key, value) {
  const db = await dbPromise;
  await db.put(storeName, value, key);
}

async function deleteFromStore(storeName, key) {
  const db = await dbPromise;
  await db.delete(storeName, key);
}

// Function to get signed URL with caching
export async function getSignedURL(imagePath) {
  const cachedUrl = await getFromStore(URL_STORE, imagePath);
  if (cachedUrl && Date.now() - cachedUrl.timestamp < EXPIRATION_TIME) {
    // 5 minutes cache
    return cachedUrl.url;
  }

  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_BASE_URL}/generate-signed-url`,
    headers: {
      Authorization: `bearer ${localStorage.getItem('accessToken')}`,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    params: {
      file_name: imagePath,
    },
  };

  const response = await axios(config);
  const signedURL = response.data;

  await setInStore(URL_STORE, imagePath, { url: signedURL, timestamp: Date.now() });
  return signedURL;
}

// Function to load and resize image with caching
export async function loadAndResizeImageFromPath(imagePath, maxWidth, maxHeight) {
  const cachedImage = await getFromStore(IMAGE_STORE, imagePath);
  if (cachedImage) {
    return resizeImage(cachedImage, maxWidth, maxHeight);
  }

  const signedURL = await getSignedURL(imagePath);
  const response = await fetch(signedURL);
  const imageBlob = await response.blob();

  await setInStore(IMAGE_STORE, imagePath, imageBlob);
  return resizeImage(imageBlob, maxWidth, maxHeight);
}

// Image resizing function
function resizeImage(blob, maxWidth, maxHeight) {
  return new Promise((resolve) => {
    ImageResizer.imageFileResizer(
      blob,
      maxWidth || Math.round(window.innerWidth * 0.6),
      maxHeight || Math.round(window.innerHeight * 0.6),
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
      Math.min(Math.round(window.innerWidth * 0.4), maxWidth || Math.round(window.innerWidth * 0.6)),
      Math.min(Math.round(window.innerHeight * 0.4), maxHeight || Math.round(window.innerHeight * 0.6))
    );
  });
}

// Function to force refresh an image
export async function resetCacheItem(imagePath) {
  await deleteFromStore(URL_STORE, imagePath);
  await deleteFromStore(IMAGE_STORE, imagePath);
}

// Function to clean up expired cache entries
export async function cleanupExpiredCache(maxAge = 24 * 60 * 60 * 1000) {
  console.log('Cleaning up expired cache entries...');
  // Default: 24 hours
  const db = await dbPromise;
  const now = Date.now();

  const cleanupStore = async (storeName) => {
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const keys = await store.getAllKeys();

    await Promise.all(
      keys.map(async (key) => {
        if (storeName === URL_STORE) {
          const entry = await store.get(key);
          if (now - entry.timestamp > maxAge) {
            await store.delete(key);
            // Also delete the corresponding image if it exists
            const imageTx = db.transaction(IMAGE_STORE, 'readwrite');
            await imageTx.objectStore(IMAGE_STORE).delete(key);
            await imageTx.done;
          }
        }
      })
    );

    await tx.done;
  };

  // Clean up URL_STORE and IMAGE_STORE
  await cleanupStore(URL_STORE);
  await cleanupStore(IMAGE_STORE);
}

// Function to initialize periodic cache cleanup
export function initCacheCleanup(intervalInMinutes = 60) {
  setInterval(cleanupExpiredCache, intervalInMinutes * 60 * 1000);
}
