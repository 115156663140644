import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const RestaurantContext = createContext();

RestaurantProvider.propTypes = {
  children: PropTypes.node,
};

function RestaurantProvider({ children }) {
  const [restaurant, setRestaurant] = useState();
  const [restaurantList, setRestaurantList] = useState([]);
  const { isAuthenticated } = useAuth();

  const getRestaurants = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/restaurants`, {
      headers: {
        Authorization: `bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    setRestaurantList(response.data);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getRestaurants();
    }
  }, [isAuthenticated]);

  const onChangeRestaurant = (event) => {
    if (event && event.target) {
      setRestaurant(event.target.value);
    }
  };

  const updateRestaurant = (id) => {
    setRestaurant(id);
  };

  const checkAndSetRestaurant = useCallback(
    (previousRestaurant) => {
      if (restaurantList.length > 0) {
        if (previousRestaurant === undefined) {
          setRestaurant(restaurantList[0].id);
        } else {
          let valid = false;
          // Verifies if the user has access to the restaurant_id in local storage
          for (let i = 0; i < restaurantList.length - 1; i += 1) {
            if (Number(previousRestaurant) === Number(restaurantList[i].id)) {
              setRestaurant(restaurantList[i].id);
              valid = true;
            }
          }
          if (valid !== true) {
            setRestaurant(restaurantList[0].id);
          }
        }
      }
    },
    [restaurantList]
  );

  useEffect(() => {
    if (restaurantList.length > 0) {
      const previousRestaurant = localStorage.getItem('restaurant_id');
      if (previousRestaurant !== undefined) {
        checkAndSetRestaurant(previousRestaurant);
      } else {
        setRestaurant(restaurantList[0].id);
      }
    }
  }, [checkAndSetRestaurant, restaurantList]);

  useEffect(() => {
    // update the local storage value to reuse it on page reload
    // but not on restaurant initial useState of undefined
    if (restaurant !== undefined) {
      localStorage.setItem('restaurant_id', restaurant);
    }
  }, [restaurant]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        restaurantList,
        setRestaurant,
        updateRestaurant,
        onChangeRestaurant,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
}

export { RestaurantProvider, RestaurantContext };
